// @use '@angular/material' as mat;

// @include mat.core();

// $dark-primary-text: rgba(black, 0.87);
// $light-primary-text: white;

// $primary-palette: (
//     100: #F5F5F5,
//     200: #EEEEEE,
//     300: #E0E0E0,
//     400: #BDBDBD,
//     500: #9E9E9E,
//     600: #757575,
//     700: #616161,
//     800: #424242,
//     900: #212121,
//     // ... continues to 900
//     contrast:
//         (
//             100: rgba(black, 0.87),
//             200: rgba(black, 0.87),
//             300: rgba(black, 0.87),
//             400: rgba(black, 0.87),
//             500: white,
//             600: white,
//             800: white,
//             700: white,
//             900: white,
//             // ... continues to 900
//         ),
// );

// $primary: mat.define-palette($primary-palette, 800);
// $accent: mat.define-palette($alternate-palette, A200, A100, A400);
// $accent: mat.define-palette(mat.$grey-palette, 300, 100, 600, A100);

// $primary: mat.define-palette(mat.$indigo-palette, 500);
// $accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);

// $typography: mat.define-typography-config(
//     $font-family: 'acumin-pro-semi-condensed, Helvetica Neue, sans-serif',
// );

// $theme: mat.define-theme(
//     (
//         color: (
//             theme-type: light,
//             primary: mat.$indigo-palette,
//             // accent: mat.$amber-palette,
//         ),
//         typography: (
//             brand-family: 'acumin-pro-semi-condensed, Helvetica Neue, sans-serif',
//         ),
//     )
// );

// @include mat.all-component-themes($theme);

/**
 * Using a custom theme
 */

// @use '@angular/material' as mat;

// @include mat.core();

// $theme: mat.define-theme(
//     (
//         color: (
//             primary: mat.$violet-palette,
//             tertiary: mat.$orange-palette,
//             theme-type: light,
//         ),
//     )
// );

// html {
//     @include mat.core-theme($theme);
//     @include mat.all-component-themes($theme);
// }


/**
 * Using a built-in theme
 */

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';


.mat-badge-content {
    overflow: visible;
}
