@use '../../shared/scss/mq.scss';
@use '../../shared/scss/mixins.scss';
@use '../../shared/scss/common.scss';
@use '../../shared/scss/fonts.scss';
@use '../../shared/scss/theme.scss';
@use '../../shared/scss/tiptap.scss';
@use '../../shared/scss/node-colours.scss';
@use '../../shared/scss/videojs.scss';
@use '../../shared/scss/overrides.scss';

.highlight {
    background-color: #0097fb;
    color: #333;
}

.mat-mdc-input-element {
    line-height: 20px;
}

button mat-spinner.mat-mdc-progress-spinner {
    display: inline-block;
    top: 1px;
    left: -2px;
}

.login {
    background-color: #fff;
}

audio {
    &:focus {
        outline: none;
    }
}

/**
  *
  * Global angular overrides
  */
.mat-mdc-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-mdc-checkbox-checked.mat-accent .mat-checkbox-background {
    background: #8e8e8e;
}

ngx-material-timepicker-container {
    position: fixed;
    z-index: 9999;
}

.mat-mdc-menu-panel.time-zones {
    width: 300px;
    max-width: 300px;
}

mat-bottom-sheet-container.notes-host {
    margin-bottom: 30px;
    padding: 0;
}

@media print {
    .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio {
        .mdc-radio__native-control:enabled + .mdc-radio__background {
            .mdc-radio__inner-circle,
            .mdc-radio__outer-circle {
                border-color: var(--mdc-radio-selected-icon-color, #6200ee) !important;
            }
        }

        .mdc-radio__native-control + .mdc-radio__background {
            .mdc-radio__inner-circle {
                transform: scale(0.5) !important;
            }
        }
    }

    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control.mdc-checkbox--selected ~ .mdc-checkbox__background {
        border-color: var(--mdc-checkbox-selected-icon-color, var(--mdc-theme-secondary, #018786)) !important;
        background-color: var(--mdc-checkbox-selected-icon-color, var(--mdc-theme-secondary, #018786)) !important;
    }

    .mdc-checkbox__native-control.mdc-checkbox--selected ~ .mdc-checkbox__background {
        .mdc-checkbox__checkmark-path {
            stroke-dashoffset: 0 !important;
        }
        .mdc-checkbox__checkmark {
            opacity: 1 !important;
        }
    }
}
