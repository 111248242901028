@use 'mixins';

html,
body {
    height: 100%;
}

body {
    margin: 0;
}

button mat-spinner {
    display: inline-block;
}

.white-dialog-backdrop {
    background-color: rgba(255, 255, 255, 1) !important;
}

.watermark {
    width: 100vw;
    height: 100vh;
    background-size: auto;
    background-position: 50%;
    position: fixed;
    overflow: hidden;
    pointer-events: none;
    z-index: 9;
    top: 0;
    @include mixins.no-user-select;
}

.pointer {
    cursor: pointer;
}

.unsupported-browser {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;

    ul {
        padding: 0 0 0 10px;
        margin: 0;

        li {
            list-style-type: none;
            padding-bottom: 10px;

            img {
                vertical-align: middle;
                width: 30px;
            }

            .name {
                padding-left: 5px;
            }

            a {
                text-decoration: underline;
                &::after {
                    content: '\e89e';
                    font-family: 'Material Icons';
                }
            }
        }
    }
}

.hidden {
    display: none;
}

.skeleton {
    background-color: #eee;
    border-radius: 4px;
    margin: 10px 0;
    position: relative;
    overflow: hidden;
}
.skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation: loading 1.5s infinite;
}
@keyframes loading {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}
.skeleton.text {
    height: 20px;
    width: 80%;
}
.skeleton.circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
.skeleton.rect {
    height: 100px;
    width: 100%;
}
