button mat-spinner.mat-mdc-progress-spinner {
    display: inline-block;
    top: 1px;
}

// .cdk-overlay-pane {
//      width: fit-content !important;
// }

.mat-select-panel {
     max-width: unset !important;
}