
// @import 'https://p.typekit.net/p.css?s=1&k=nur5yta&ht=tk&f=26036.26037.26044.26046.26047&a=14638192&app=typekit&e=css';

@font-face {
    font-family: 'acumin-pro-semi-condensed';
    src: url('../fonts/acumin-pro-semi-condensed-regular-400.woff2') format('woff2'),
        url('../fonts/acumin-pro-semi-condensed-regular-400.woff') format('woff'),
        url('../fonts/acumin-pro-semi-condensed-regular-400.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'acumin-pro-semi-condensed';
    src: url('../fonts/acumin-pro-semi-condensed-italic-400.woff2') format('woff2'),
        url('../fonts/acumin-pro-semi-condensed-italic-400.woff') format('woff'),
        url('../fonts/acumin-pro-semi-condensed-italic-400.otf') format('opentype');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'acumin-pro-semi-condensed';
    src: url('../fonts/acumin-pro-semi-condensed-regular-700.woff2') format('woff2'),
        url('../fonts/acumin-pro-semi-condensed-regular-700.woff') format('woff'),
        url('../fonts/acumin-pro-semi-condensed-regular-700.otf') format('opentype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'acumin-pro-semi-condensed';
    src: url('../fonts/acumin-pro-semi-condensed-italic-700.woff2') format('woff2'),
        url('../fonts/acumin-pro-semi-condensed-italic-700.woff') format('woff'),
        url('../fonts/acumin-pro-semi-condensed-italic-700.otf') format('opentype');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'acumin-pro-semi-condensed';
    src: url('../fonts/acumin-pro-semi-condensed-regular-600.woff2') format('woff2'),
        url('../fonts/acumin-pro-semi-condensed-regular-600.woff') format('woff'),
        url('../fonts/acumin-pro-semi-condensed-regular-600.otf') format('opentype');
    font-style: normal;
    font-weight: 600;
}