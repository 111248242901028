@use 'mixins';

ul[data-type='taskList'] {
    list-style: none;
    padding: 0;

    p {
        margin: 0;
    }

    li {
        display: flex;

        > label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            @include mixins.no-user-select;
        }

        > div {
            flex: 1 1 auto;
        }
    }
}

.ProseMirror {
    line-height: 1.42;
    padding: 12px 15px;
    font-size: 1em;
    position: relative;
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: break-spaces;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    font-feature-settings: 'liga' 0; /* the above doesn't seem to work in Edge */

    &[contenteditable='false'] {
        white-space: normal;
        white-space: pre-wrap;
    }

    &[contenteditable='true'] {
        min-height: 150px;
        border: solid 1px #ddd;
        border-top: none;
        padding: 10px;
    }

    &.ProseMirror-focused:focus-visible {
        outline: none;
    }

    > p:first-of-type {
        margin-top: 0;
    }

    p {
        margin: 0;
        min-height: 22px; // for empty paragraph
    }

    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    pre,
    ul {
        // margin: 0;
        padding: 0;
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    pre {
        white-space: pre-wrap;
    }

    img {
        max-width: 100%;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.5em;
    }

    // p {
    //     margin: 0;
    //     &:before {
    //         content: ' ';
    //     }
    // }

    ol,
    ul {
        padding-left: 1em;

        // > li:before {
        //     content: '\2022';
        // }

        li {
            // list-style-type: none;
            padding-left: 0;

            > p {
                display: inline;
                margin: 0;
            }

            &:before {
                margin-left: -1em;
                margin-right: 0.3em;
                text-align: right;
                display: inline-block;
                white-space: nowrap;
                width: 1.2em;
            }
        }
    }

    a.uploading {
        animation: blink 1s linear infinite;
    }

    video.uploading {
        animation: blink 1s linear infinite;

        &::after {
            display: block;
            content: attr(data-progress);
        }
    }
}

.ProseMirror .custom-image-audio,
.custom-image-audio {
    border: 1px solid #000;
    background-color: #fff;
    height: 100px;
    max-width: 350px;
    width: 100%;
    pointer-events: all;
}

.ProseMirror .custom-image-small,
.custom-image-small {
    max-width: 200px;
}

.ProseMirror .custom-image-medium,
.custom-image-medium {
    max-width: 500px;
    width: 100%;
}

.ProseMirror .custom-image-large,
.custom-image-large {
    max-width: 100%;
}

.ProseMirror video.custom-image-large,
video.custom-image-large {
    max-width: 100%;
    min-width: 100%;
}

.ProseMirror .custom-video-small,
.custom-video-small {
    max-width: 200px;
}

.ProseMirror .custom-video-medium,
.custom-video-medium {
    max-width: 500px;
    width: 100%;
}

.ProseMirror .custom-video-large,
.custom-image-large {
    max-width: 100%;
    min-width: 100%;
}

.ProseMirror img.ProseMirror-selectednode,
.ProseMirror div[data-upload-video].ProseMirror-selectednode video {
    outline: 2px dotted #ff8800;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
