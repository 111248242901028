.node-colour-0 {
    color: #000;
}
.node-colour-1 {
    color: #ea5e50;
}
.node-colour-2 {
    color: #f9ae54;
}
.node-colour-3 {
    color: #bc996e;
}
.node-colour-4 {
    color: #f9e562;
}
.node-colour-5 {
    color: #92c58f;
}
.node-colour-6 {
    color: #7ce3c6;
}
.node-colour-7 {
    color: #6897f2;
}
.node-colour-8 {
    color: #a981eb;
}
.node-colour-9 {
    color: #f1b0d5;
}
.node-colour-10 {
    color: #444;
}
.node-colour-11 {
    color: #bfbfbf;
}
.node-colour-12 {
    color: rgb(255, 89, 219);
}

.colour-bg-1 {
    background-color: #ea5e50;
    &.mat-mdc-card {
        background-color: #ea5e50;
    }
}
.colour-bg-2 {
    background-color: #f9ae54;
    &.mat-mdc-card {
        background-color: #f9ae54;
    }
}
.colour-bg-3 {
    background-color: #bc996e;
    &.mat-mdc-card {
        background-color: #bc996e;
    }
}
.colour-bg-4 {
    background-color: #f9e562;
    &.mat-mdc-card {
        background-color: #f9e562;
    }
}
.colour-bg-5 {
    background-color: #92c58f;
    &.mat-mdc-card {
        background-color: #92c58f;
    }
}
.colour-bg-6 {
    background-color: #7ce3c6;
    &.mat-mdc-card {
        background-color: #7ce3c6;
    }
}
.colour-bg-7 {
    background-color: #6897f2;
    &.mat-mdc-card {
        background-color: #6897f2;
    }
}
.colour-bg-8 {
    background-color: #a981eb;
    &.mat-mdc-card {
        background-color: #a981eb;
    }
}
.colour-bg-9 {
    background-color: #f1b0d5;
    &.mat-mdc-card {
        background-color: #f1b0d5;
    }
}
.colour-bg-10 {
    background-color: #444;
    &.mat-mdc-card {
        background-color: #444;
    }
}
.colour-bg-11 {
    background-color: #bfbfbf;
    &.mat-mdc-card {
        background-color: #bfbfbf;
    }
}
.colour-bg-12 {
    background-color: rgb(255, 89, 219);
    &.mat-mdc-card {
        background-color: rgb(255, 89, 219);
    }
}
.colour-bg-13 {
    background-color: rgb(235, 235, 235);
    &.mat-mdc-card {
        background-color: rgb(235, 235, 235);
    }
}

.colour-1 {
    color: #ea5e50;
}
.colour-2 {
    color: #f9ae54;
}
.colour-3 {
    color: #bc996e;
}
.colour-4 {
    color: #f9e562;
}
.colour-5 {
    color: #92c58f;
}
.colour-6 {
    color: #7ce3c6;
}
.colour-7 {
    color: #6897f2;
}
.colour-8 {
    color: #a981eb;
}
.colour-9 {
    color: #f1b0d5;
}
.colour-10 {
    color: #444;
}
.colour-11 {
    color: #bfbfbf;
}
.colour-12 {
    color: rgb(255, 89, 219);
}
.colour-13 {
    color: rgb(235, 235, 235);
}
