@use 'mixins';
@use 'sass-mq/mq';

.vjs-starting-soon {
    @include mixins.no-user-select;
    text-align: center;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    font-size: 2em;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq.mq($from: medium) {
        font-size: 3em;
    }

    span {
        animation: blink 2s ease-in-out;
        animation-delay: 1000ms;
        animation-iteration-count: infinite;
        flex: 1 1 auto;
        color: #fff;
    }
}

.vjs-watermark {
    // background: rgba(0, 0, 0, 0.9);
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0, rgba(0, 0, 0, 0.7) 60%, rgba(0, 0, 0, 0) 100%);
    font-size: 2.5em;
    line-height: 1.5;
    transition: opacity 0.1s;
    padding: 0.666em 1.333em 4em;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;

    background-size: cover;
    background-position: -50%;
}

.video-js .vjs-tap2play {
    display: none;
}

.video-js.vjs-no-interaction .vjs-tap2play {
    font-size: 3em;
    line-height: 1.5em;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    background-color: #2b333f;
    background-color: rgba(43, 51, 63, 0.7);
    transition: all 0.4s;
}

.vjs-controls-disabled .vjs-tap2play,
.vjs-error .vjs-tap2play,
.vjs-using-native-controls .vjs-tap2play {
    display: none;
}

.vjs-fullscreen .vjs-theatre-control {
    display: none;
}

.vjs-theatre-control {
    cursor: pointer;
}

.video-js.vjs-ios-fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}
